export const columns = [
  { Header: "Pos", accessor: "pos" },
  { Header: "Start", accessor: "bib" },
  { Header: "Athlete", accessor: "participant" },
  { Header: "Gender", accessor: "gender" },
  { Header: "Division", accessor: "cat" },
  { Header: "Nationality", accessor: "nationality" },
  { Header: "Time", accessor: "time" },
];

export const columnsSmall = ["pos", "participant", "nationality", "time"];

export const columnsSplitPoints = [
  { Header: "Location", accessor: "locationName" },
  { Header: "Time", accessor: "raceTime" },
];
