import "./ResultsSoon.scss";

function ResultsSoon() {
  return (
    <div className="results-soon">
      <h3>Results should appear soon. Stay tuned!</h3>
    </div>
  );
}

export default ResultsSoon;
