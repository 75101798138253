import callApi from "./callApi";

async function fetchRankingInfo(eventId, raceId) {
  const url = `ranking/${eventId}/${raceId}/`;

  try {
    const response = await callApi(url, "GET");
    return response.json();
  } catch (error) {
    return { error };
  }
}

export default fetchRankingInfo;
