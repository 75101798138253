import PropTypes from "prop-types";
import './NationalityComponent.scss';

function NationalityComponent({ flag, nationality }) {
  return (
    <>
      <img
        alt={flag}
        className="nationality-image"
        src={`https://intraim.event-timing.com/images/flags/${flag?.toLowerCase()}.gif`}
      />{" "}
      - {nationality}
    </>
  );
}

NationalityComponent.propTypes = {
  flag: PropTypes.string,
  nationality: PropTypes.string,
};

export default NationalityComponent;
