import { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DropdownButton, Dropdown, Pagination } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { ModalParticipant } from "./ModalParticipant";
import NationalityComponent from "./NationalityComponent";
import "./ResultsTable.scss";

function ResultsTable({
  results,
  columns,
  columnsSmall,
  eventId,
  isTableClickable,
  isDiffColumnsMobile,
  showTimingLocationsSeparator,
}) {
  const [resultsData, setResultsData] = useState(results);
  const [searchValue, setSearchValue] = useState("");
  const [showParticipantModal, setShowParticipantModel] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: resultsData,
      defaultColumn: "name",
      initialState: { pageIndex: 0 },
      autoResetGlobalFilter: false,
      autoResetPage: false,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });
  const showSmallTable = isDiffColumnsMobile && isMobile;

  // maybe do a deep equal with lodash
  useEffect(() => {
    if (results.length > 0) {
      setResultsData(results);

      if (searchValue) {
        onChangeSearch(searchValue);
      }
    }
  }, [results]);

  const showTimingLocationSeparator = (timingLocation, i) => (
    <tr className="timing-seperator-row" key={i}>
      <td
        className="timing-seperator-column"
        colSpan={7}
        key={`separator-${i}`}
      >
        {timingLocation}
      </td>
    </tr>
  );

  const handlePaging = (pageIndex, pageCount, pageOptions) => {
    if (pageCount > 10) {
      return (
        <>
          <Pagination.Item onClick={() => gotoPage(0)} active={pageIndex === 0}>
            {1}
          </Pagination.Item>
          <Pagination.Item onClick={() => gotoPage(1)} active={pageIndex === 1}>
            {2}
          </Pagination.Item>
          {pageIndex > 1 && pageIndex < pageCount - 2 && (
            <>
              <Pagination.Ellipsis />
              <Pagination.Item onClick={() => gotoPage(pageIndex + 1)} active>
                {pageIndex + 1}
              </Pagination.Item>
              <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>
                {pageIndex + 2}
              </Pagination.Item>
            </>
          )}
          <Pagination.Ellipsis />
          <Pagination.Item
            onClick={() => gotoPage(pageCount - 2)}
            active={pageIndex === pageCount - 2}
          >
            {pageCount - 1}
          </Pagination.Item>
          <Pagination.Item
            key={pageCount - 1}
            onClick={() => gotoPage(pageCount - 1)}
            active={pageIndex === pageCount - 1}
          >
            {pageCount}
          </Pagination.Item>
        </>
      );
    }

    return pageOptions.map((_, i) => (
      <Pagination.Item
        key={i + 1}
        active={pageIndex + 1 === i + 1}
        onClick={() => gotoPage(i)}
      >
        {i + 1}
      </Pagination.Item>
    ));
  };

  const handleCloseModal = () => {
    setShowParticipantModel(false);
  };

  const handleShowModal = (row) => {
    if (isTableClickable) {
      setSelectedParticipant(row.original);
      setShowParticipantModel(true);
    }
  };

  const onChangeSearch = useAsyncDebounce((value) =>
    setGlobalFilter(value || undefined)
  );

  const renderCell = (cell) => {
    if (cell.column.Header === "Nationality") {
      return (
        <td className="results-table-cell" {...cell.getCellProps()}>
          <NationalityComponent
            flag={cell.row.original.flag}
            nationality={cell.row.original.nationality}
          />
        </td>
      );
    }

    if (
      cell.column.Header === "Time" &&
      cell.row.original.tod &&
      cell.row.original.tod !== "" &&
      !cell.row.original.raceTime
    ) {
      return (
        <td className="results-table-cell--italic" {...cell.getCellProps()}>
          {cell.row.original.tod}
        </td>
      );
    }

    if (cell.column.Header === "Pos" && cell.row.original.status) {
      return (
        <td className="results-table-cell" {...cell.getCellProps()}>
          {cell.row.original.status}
        </td>
      );
    }

    return (
      <td className="results-table-cell" {...cell.getCellProps()}>
        {cell.render("Cell")}
      </td>
    );
  };

  return (
    <div className="results-table-container">
      <ModalParticipant
        show={showParticipantModal}
        handleClose={handleCloseModal}
        participant={selectedParticipant}
        eventId={eventId}
      />
      <span className="search-container">
        Search:{" "}
        <input
          className="search-input"
          value={searchValue || ""}
          onChange={(e) => {
            setSearchValue(e.target.value);
            onChangeSearch(e.target.value);
          }}
        />
      </span>
      <table className="results-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (!(showSmallTable && !columnsSmall?.includes(column.id))) {
                  return (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  );
                }
                return null;
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Fragment key={i}>
                {showTimingLocationsSeparator &&
                  i === 0 &&
                  showTimingLocationSeparator(row.original.timingLocation, i)}
                {showTimingLocationsSeparator &&
                  i > 0 &&
                  page[i - 1].original.timingLocation !==
                    row.original.timingLocation &&
                  showTimingLocationSeparator(row.original.timingLocation, i)}
                <tr
                  onClick={() => handleShowModal(row)}
                  className="results-table-row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    if (
                      !(
                        showSmallTable && !columnsSmall.includes(cell.column.id)
                      )
                    ) {
                      return renderCell(cell);
                    }
                    return null;
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="results-table-options">
        <Pagination>
          <Pagination.First
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
          {handlePaging(pageIndex, pageCount, pageOptions)}
          <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </Pagination>
        <div className="page-size-container">
          <label className="page-size-label">Page size</label>
          <DropdownButton title={pageSize}>
            {[10, 20, 30, 40, 50].map((item, i) => (
              <Dropdown.Item
                key={i}
                active={item === pageSize}
                onClick={() => setPageSize(item)}
              >
                {item}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}

ResultsTable.propTypes = {
  results: PropTypes.array,
  columns: PropTypes.array,
  columnsSmall: PropTypes.array,
  eventId: PropTypes.string,
  isTableClickable: PropTypes.bool,
  isDiffColumnsMobile: PropTypes.bool,
  showTimingLocationsSeparator: PropTypes.bool,
};

export default ResultsTable;
