import callApi from "./callApi";

async function fetchEventService(eventId) {
  const url = `event/${eventId}/`;
  try {
    const response = await callApi(url, "GET");
    return response.json();
  } catch (error) {
    return { error };
  }
}

export default fetchEventService;