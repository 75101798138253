import "./ResultsContainer.scss";
import ResultsSelectionContainer from "./ResultsSelectionContainer";
import { useParams } from 'react-router-dom'

function ResultsContainer() {
  const { eventId } = useParams();
  return (
    <div className="results-container">
      <div className="results-card">
        <h1 className="results-title">Live Results</h1>
        <ResultsSelectionContainer eventId={eventId} />
      </div>
    </div>
  );
}

export default ResultsContainer;
