async function callApi(url, method) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method,
    headers: {
      authorization: `${process.env.REACT_APP_API_TOKEN}`,
    },
  });

  if ((response.status >= 200 && response.status <= 299)) {
    return response;
  } else {
    throw Error(response.statusText);
  }
}

export default callApi;
