import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fetchParticipantResults } from "../services/resultsServices";
import ResultsTable from "./ResultsTable";
import ParticipantInfoComponent from "./ParticipantInfoComponent";
import { columnsSplitPoints } from "../utils/TableConstants";
import "./ModalParticipant.scss";
import SpinnerComponent from "./SpinnerComponent";

export async function getParticipantResults(
  eventId,
  pId,
  setSplitResults,
  setIsLoading
) {
  const results = await fetchParticipantResults(eventId, pId);
  setIsLoading(false);
  if (results?.error) {
    return results.error;
  }
  setSplitResults(results.data);
}

export function ModalParticipant({ eventId, show, handleClose, participant }) {
  const [splitResults, setSplitResults] = useState([]);
  const [isLoadingSplits, setIsLoadingSplits] = useState(false);

  useEffect(() => {
    if (show) {
      setIsLoadingSplits(true);
      getParticipantResults(
        eventId,
        participant.bib,
        setSplitResults,
        setIsLoadingSplits
      );
    } else {
      setSplitResults([]);
    }
  }, [show]);

  return (
    <Modal className="participant-modal" show={show} centered>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>Participant Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ParticipantInfoComponent {...participant} />
        {isLoadingSplits ? (
          <div className="participant-modal-spinner">
            <SpinnerComponent />
          </div>
        ) : (
          <ResultsTable columns={columnsSplitPoints} results={splitResults} />
        )}
      </Modal.Body>
    </Modal>
  );
}

ModalParticipant.propTypes = {
  eventId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  participant: PropTypes.object,
};
