import { Button } from "react-bootstrap";

function ErrorComponent() {
  return (
    <div className="error-container">
      <h3> Something went worng </h3>
      <Button title="Retry" onClick={() => window.location.reload()}>
        Retry
      </Button>
    </div>
  );
}

export default ErrorComponent;
