import callApi from "./callApi";

async function fetchRaceInfo(eventId) {
  const url = `race/${eventId}/`;
  try {
    const response = await callApi(url, "GET");
    return response.json();
  } catch (error) {
    return { error };
  }
}

export default fetchRaceInfo;
