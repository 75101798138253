import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "./DropdownComponent.scss";

function DropdownComponent({
  headerTitle,
  title,
  items,
  itemSelector,
  itemIdSelector,
  onItemClick,
}) {
  return (
    <div className="dropdown-container">
      <span>{headerTitle}</span>
      <DropdownButton title={title}>
        {items.map((item, i) => (
          <Dropdown.Item
            key={i}
            id={item[itemIdSelector]}
            active={item.active}
            onClick={() => onItemClick(item[itemIdSelector])}
          >
            {item[itemSelector]}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
}

DropdownComponent.propTypes = {
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  itemSelector: PropTypes.string,
  itemIdSelector: PropTypes.string,
  onItemClick: PropTypes.func,
};

export default DropdownComponent;
