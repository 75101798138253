import PropTypes from "prop-types";
import { genderDecoder } from "../utils/Decoders";
import NationalityComponent from "./NationalityComponent";
import ParticipantIconComponent from "./ParticipantIconComponent";
import "./ParticipantInfoComponent.scss";

function ParticipantInfoComponent({
  participant,
  cat,
  nationality,
  flag,
  team,
  gender,
}) {
  return (
    <div className="participant-info-container">
      <ParticipantIconComponent name={participant} />
      <div className="participant-info">
        <p>
          <b>Name:</b> {participant}
        </p>
        <p>
          <b>Category:</b> {cat}
        </p>
        <p>
          <b>Gender:</b> {genderDecoder(gender)}
        </p>
        {nationality && (
          <p>
            <b>Nationality:</b>{" "}
            <NationalityComponent flag={flag} nationality={nationality} />
          </p>
        )}
        {team && (
          <p>
            <b>Team:</b> {team}
          </p>
        )}
      </div>
    </div>
  );
}

ParticipantInfoComponent.propTypes = {
  participant: PropTypes.string,
  cat: PropTypes.string,
  nationality: PropTypes.string,
  team: PropTypes.string,
};

export default ParticipantInfoComponent;
