import "./NoResultsContainer.scss";

function NoResultsContainer() {
  return (
    <div className="no-results-container">
      <h3> No results available </h3>
    </div>
  );
}

export default NoResultsContainer;
