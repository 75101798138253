import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Row, Container } from "react-bootstrap";
import DropdownComponent from "../components/DropdownComponent";
import ErrorComponent from "../components/ErrorComponent";
import ResultsSoon from "../components/ResultsSoon";
import ResultsTable from "../components/ResultsTable";
import SpinnerComponent from "../components/SpinnerComponent";
import fetchRaceInfo from "../services/raceServices";
import fetchRankingInfo from "../services/rankingServices";
import { fetchResultsInfo } from "../services/resultsServices";
import { columns, columnsSmall } from "../utils/TableConstants";
import "./ResultsSelectionContainer.scss";

export const getRaceInfo = async (eventId, setRaces, setError) => {
  const races = await fetchRaceInfo(eventId);
  if (!races?.error) {
    const racesData = races.data.map((race, i) => ({
      ...race,
      active: i === 0,
    }));
    setRaces(racesData);
  } else {
    setError(races.error);
  }
};

export const getRankingInfo = async (eventId, raceId, setRankings, setError) => {
  const response = await fetchRankingInfo(eventId, raceId);
  if (!response?.error) {
    const rankingsData = response?.data.map((race, i) => ({
      ...race,
      active: i === 0,
    }));

    setRankings(rankingsData);
  } else {
    setError(response.error);
  }
};

export const getResultsInfo = async (
  eventId,
  raceId,
  rankingId,
  setResults,
  setIsLoadingDiffResults,
  setNoDataAvailable,
  setError
) => {
  const results = await fetchResultsInfo(eventId, raceId, rankingId);
  setIsLoadingDiffResults(false);

  if (!results?.error) {
    setResults(results.data);

    if (results?.data?.length === 0) {
      setNoDataAvailable(true);
    } else {
      setNoDataAvailable(false);
    }
  } else {
    setError(results.error);
  }
};

const INTERVAL_RESULTS_POOL = 10000;

function ResultsSelectionContainer({ eventId }) {
  const [races, setRaces] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [results, setResults] = useState([]);
  const [isLoadingDiffResults, setIsLoadingDiffResults] = useState(false);
  const [error, setError] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const resultsTimer = useRef(null);

  useEffect(() => {
    if (eventId) {
      getRaceInfo(eventId, setRaces, setError);
    }
  }, [eventId]);

  useEffect(() => {
    if (races?.length > 0) {
      const activeRace = races.find((race) => race.active)?.race;
      getRankingInfo(eventId, activeRace, setRankings, setError);
    }
  }, [races]);

  useEffect(() => {
    const activeRace = races.find((race) => race.active)?.race;
    const activeRanking = rankings.find((ranking) => ranking.active)?.rankingId;
    if (eventId && activeRace && activeRanking) {
      clearInterval(resultsTimer.current);
      setIsLoadingDiffResults(true);
      getResultsInfo(
        eventId,
        activeRace,
        activeRanking,
        setResults,
        setIsLoadingDiffResults,
        setNoDataAvailable,
        setError
      );

      resultsTimer.current = setInterval(
        async () =>
          await getResultsInfo(
            eventId,
            activeRace,
            activeRanking,
            setResults,
            setIsLoadingDiffResults,
            () => {}
          ),
        INTERVAL_RESULTS_POOL
      );
    }
  }, [rankings]);

  const handleRaceChange = (raceId) => {
    const newRaces = races.map((race) => ({
      ...race,
      active: raceId === race.race,
    }));
    setRaces(newRaces);
  };

  const titleForRaceDropdown = () => {
    return races?.find((race) => race.active)?.raceName || "Select a race";
  };

  const handleRankingChange = (rankingId) => {
    const newRankings = rankings.map((ranking) => ({
      ...ranking,
      active: rankingId === ranking.rankingId,
    }));
    setRankings(newRankings);
  };

  const titleForRankingropdown = () => {
    return (
      rankings.find((ranking) => ranking.active)?.rankingName ||
      "Select a ranking"
    );
  };

  const renderTableOrLoadingOrError = () => {
    if (error) {
      return <ErrorComponent error={error} />;
    }

    if (noDataAvailable) {
      return <ResultsSoon />;
    }

    if (!isLoadingDiffResults) {
      return (
        <ResultsTable
          results={results ? results : []}
          columns={columns}
          columnsSmall={columnsSmall}
          eventId={eventId}
          isTableClickable
          isDiffColumnsMobile
          showTimingLocationsSeparator
        />
      );
    }

    return (
      <div className="results-table-spinner">
        <SpinnerComponent />
      </div>
    );
  };

  return (
    <Container>
      <div className="dropdown-area-container">
        <DropdownComponent
          headerTitle="Select a Race"
          title={titleForRaceDropdown()}
          items={races}
          itemSelector="raceName"
          itemIdSelector="race"
          onItemClick={handleRaceChange}
        />
        <DropdownComponent
          headerTitle="Select a Ranking"
          title={titleForRankingropdown()}
          items={rankings}
          itemSelector="rankingName"
          itemIdSelector="rankingId"
          onItemClick={handleRankingChange}
        />
      </div>
      <Row>{renderTableOrLoadingOrError()}</Row>
    </Container>
  );
}

ResultsSelectionContainer.propTypes = {
  eventId: PropTypes.string,
};

export default ResultsSelectionContainer;
