import callApi from "./callApi";

export async function fetchResultsInfo(eventId, raceId, rankingId) {
  const url = `cache/${eventId}/${raceId}/${rankingId}/`;
  try {
    const response = await callApi(url, "GET");
    return response.json();
  } catch (error) {
    return { error };
  }
}

export async function fetchParticipantResults(eventId, pId) {
  const url = `result/${pId}/${eventId}/`;
  try {
    const response = await callApi(url, "GET");
    return response.json();
  } catch (error) {
    return { error };
  }
}
