import PropTypes from "prop-types";
import './ParticipantIconComponent.scss';

function ParticipantIconComponent({ name }) {
    const allNames = name.split(' ');
    if (allNames.length > 0) {
        const firstNameLetter = allNames[0].substring(0, 1).toUpperCase();
        const lastNameLetter = allNames.length > 1 ? allNames[allNames.length - 1].substring(0, 1).toUpperCase() : '';
        return <div className="participant-icon">
            <span>{firstNameLetter}{lastNameLetter}</span>
        </div>
    }

    return undefined;

}

ParticipantIconComponent.propTypes = {
    name: PropTypes.string.isRequired,
}

export default ParticipantIconComponent;